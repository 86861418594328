// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Navbar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer/Footer";
// import LandingPage from "./components/LandingPage";
// import FunZone from "./components/Buttons/FunZoneButton";
// import LoginPage from "./components/Login/LoginPage";
// import ShippingDeliveryPolicy from "./components/Terms/Shipping & Delivery Policy"
// import ReturnCancellationPolicy from "./components/Terms/Return & Cancellation Policy";
// import PrivacyPolicy from "./components/Terms/Privacy Policy";
// import TermsCondition from "./components/Terms/terms";
// import ProfilePage from "./components/Profile/ProfilePage";
// import GamesPage from "./components/Dashboard/GamesPage";
// import VideoCardsPage from "./components/Dashboard/VideoCardsPage";
// import ProductPage from "./components/Dashboard/ProductPage";
// import TasksPage from "./components/Task/TasksPage";
// import ProductDetailsPage from "./components/Product/ProductDetailsPage"
// import VideoTabs from "./components/Task/Taskdetails"
// // import CheckoutPage from "./components/checkout/checkout"
// // import ResponsiveCheckoutPage from "./components/checkout/demo"
// import CheckoutPage1 from "./components/checkout/checkout"
// import ChatButton from "./components/Buttons/ChatButton";
// import "./App.css";

// function App() {
//   return (
//     <Router>
//       <Navbar />
//       <Routes>
//         <Route path="/" element={<LandingPage />} />
//         <Route path="/funzone" element={<FunZone />} />
//         <Route path="/login" element={<LoginPage />} />
//         <Route path="/profile" element={<ProfilePage />} />
//         <Route path="/sdp" element={<ShippingDeliveryPolicy />} />
//         <Route path="/rcp" element={<ReturnCancellationPolicy />} />
//         <Route path="/pp" element={<PrivacyPolicy />} />
//         <Route path="/tc" element={<TermsCondition />} />
//         <Route path="/dashboard" element={<GamesPage />} />
//         <Route path="/vid" element={<VideoCardsPage />} />
//         <Route path="/levels/:gameCode" element={<ProductPage />} />
//         {/* <Route path="/pdg" element={<ProductDetailsPage />} /> */}
//         <Route path="/tasks/:levelCode" element={<TasksPage />} />
//         <Route path="/stem-learning-kit/:levelCode" element={<ProductDetailsPage />} />
//         <Route path="/task-details/:taskCode" element={<VideoTabs />} />
//         {/* <Route path="/checkout" element={<CheckoutPage />} /> */}
//         {/* <Route path="/demo" element={<ResponsiveCheckoutPage />} /> */}
//         <Route path="/checkout" element={<CheckoutPage1 />} />

        
//       </Routes>
//       <ChatButton/>
//       <Footer/>
//     </Router>
//   );
// }

// export default App;


// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Navbar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer/Footer";
// import LandingPage from "./components/LandingPage";
// import FunZone from "./components/Buttons/FunZoneButton";
// import LoginPage from "./components/Login/LoginPage";
// import ShippingDeliveryPolicy from "./components/Terms/Shipping & Delivery Policy";
// import ReturnCancellationPolicy from "./components/Terms/Return & Cancellation Policy";
// import PrivacyPolicy from "./components/Terms/Privacy Policy";
// import TermsCondition from "./components/Terms/terms";
// import ProfilePage from "./components/Profile/ProfilePage";
// import GamesPage from "./components/Dashboard/GamesPage";
// import VideoCardsPage from "./components/Dashboard/VideoCardsPage";
// import ProductPage from "./components/Dashboard/ProductPage";
// import TasksPage from "./components/Task/TasksPage";
// import ProductDetailsPage from "./components/Product/ProductDetailsPage";
// import VideoTabs from "./components/Task/Taskdetails";
// // import CheckoutPage from "./components/checkout/checkout";
// // import ResponsiveCheckoutPage from "./components/checkout/demo";
// import CheckoutPage1 from "./components/checkout/ckt";
// import ChatButton from "./components/Buttons/ChatButton";
// import DownloadAppButton from "./components/Buttons/downloadapp";
// import ProtectedRoute from "./components/Login/ProtectedRoute";
// import "./App.css";

// function App() {
//   return (
//     <Router>
//       <Navbar />
//       <Routes>
//         {/* Public Routes */}
//         <Route path="/" element={<LandingPage />} />
//         <Route path="/funzone" element={<FunZone />} />
//         <Route path="/login" element={<LoginPage />} />
//         <Route path="/sdp" element={<ShippingDeliveryPolicy />} />
//         <Route path="/rcp" element={<ReturnCancellationPolicy />} />
//         <Route path="/pp" element={<PrivacyPolicy />} />
//         <Route path="/tc" element={<TermsCondition />} />
//         <Route path="/profile" element={<ProfilePage />} />
//         <Route path="/dashboard" element={<GamesPage />} />
//         <Route path="/vid" element={<VideoCardsPage />} />
//         <Route path="/levels/:gameCode" element={<ProductPage />} />
//         <Route path="/tasks/:levelCode" element={<TasksPage />} />
//         <Route path="/stem-learning-kit/:levelCode" element={<ProductDetailsPage />} />
//         <Route path="/task-details/:taskCode" element={<VideoTabs />} />

//         {/* Protected Route */}
//         <Route
//           path="/checkout"
//           element={
//             <ProtectedRoute>
//               <CheckoutPage1 />
//             </ProtectedRoute>
//           }
//         />
//       </Routes>
//       <DownloadAppButton/>
//       <ChatButton />
//       <Footer />
//     </Router>
//   );
// }

// export default App;



// import React, { useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// import ReactGA from "react-ga4";
// import Navbar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer/Footer";
// import LandingPage from "./components/LandingPage";
// import FunZone from "./components/Buttons/FunZoneButton";
// import LoginPage from "./components/Login/LoginPage";
// import ShippingDeliveryPolicy from "./components/Terms/Shipping & Delivery Policy";
// import ReturnCancellationPolicy from "./components/Terms/Return & Cancellation Policy";
// import PrivacyPolicy from "./components/Terms/Privacy Policy";
// import TermsCondition from "./components/Terms/terms";
// import ProfilePage from "./components/Profile/ProfilePage";
// import GamesPage from "./components/Dashboard/GamesPage";
// import VideoCardsPage from "./components/Dashboard/VideoCardsPage";
// import ProductPage from "./components/Dashboard/ProductPage";
// import TasksPage from "./components/Task/TasksPage";
// import ProductDetailsPage from "./components/Product/ProductDetailsPage";
// import VideoTabs from "./components/Task/Taskdetails";
// import CheckoutPage1 from "./components/checkout/ckt";
// import ChatButton from "./components/Buttons/ChatButton";
// import DownloadAppButton from "./components/Buttons/downloadapp";
// import ProtectedRoute from "./components/Login/ProtectedRoute";
// import "./App.css";

// // Function to initialize Google Analytics
// const initializeGA = () => {
//   const measurementId = "G-CJFK63W88N"; // Replace with your actual Measurement ID
//   ReactGA.initialize(measurementId);
// };

// // Component to track page views
// const TrackPageView = () => {
//   const location = useLocation();

//   useEffect(() => {
//     ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
//   }, [location]);

//   return null;
// };

// function App() {
//   // Initialize GA on app load
//   useEffect(() => {
//     initializeGA();
//   }, []);

//   return (
//     <Router>
//       {/* Track page views on route changes */}
//       <TrackPageView />
      
//       <Navbar />
//       <Routes>
//         {/* Public Routes */}
//         <Route path="/" element={<LandingPage />} />
//         <Route path="/funzone" element={<FunZone />} />
//         <Route path="/login" element={<LoginPage />} />
//         <Route path="/sdp" element={<ShippingDeliveryPolicy />} />
//         <Route path="/rcp" element={<ReturnCancellationPolicy />} />
//         <Route path="/pp" element={<PrivacyPolicy />} />
//         <Route path="/tc" element={<TermsCondition />} />
//         <Route path="/profile" element={<ProfilePage />} />
//         <Route path="/dashboard" element={<GamesPage />} />
//         <Route path="/vid" element={<VideoCardsPage />} />
//         <Route path="/levels/:gameCode" element={<ProductPage />} />
//         <Route path="/tasks/:levelCode" element={<TasksPage />} />
//         <Route path="/stem-learning-kit/:levelCode" element={<ProductDetailsPage />} />
//         <Route path="/task-details/:taskCode" element={<VideoTabs />} />

//         {/* Protected Route */}
//         <Route
//           path="/checkout"
//           element={
//             <ProtectedRoute>
//               <CheckoutPage1 />
//             </ProtectedRoute>
//           }
//         />
//       </Routes>
//       <DownloadAppButton />
//       <ChatButton />
//       <Footer />
//     </Router>
//   );
// }

// export default App;



import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import LandingPage from "./components/LandingPage";
import FunZone from "./components/Buttons/FunZoneButton";
import LoginPage from "./components/Login/LoginPage";
import ShippingDeliveryPolicy from "./components/Terms/Shipping & Delivery Policy";
import ReturnCancellationPolicy from "./components/Terms/Return & Cancellation Policy";
import PrivacyPolicy from "./components/Terms/Privacy Policy";
import TermsCondition from "./components/Terms/terms";
import ProfilePage from "./components/Profile/ProfilePage";
import OrderPage from "./components/Profile/OrderPage";
import Invoice from "./components/Profile/invoice";
import OrderDetails from "./components/Profile/OrderDetails";
import GamesPage from "./components/Dashboard/GamesPage";
import VideoCardsPage from "./components/Dashboard/VideoCardsPage";
import ProductPage from "./components/Dashboard/ProductPage";
import TasksPage from "./components/Task/TasksPage";
import ProductDetailsPage from "./components/Product/ProductDetailsPage";
import VideoTabs from "./components/Task/Taskdetails";
import CheckoutPage1 from "./components/checkout/ckt";
import ChatButton from "./components/Buttons/ChatButton";
import DownloadAppButton from "./components/Buttons/downloadapp";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import { getEnvironmentDetails } from "./environment"; // Import environment utility
import "./App.css";

// Component to track page views
const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
};

function App() {
  // Get the environment details (BASE_URL and GA_ID) from the utility
  const { BASE_URL, GA_ID } = getEnvironmentDetails();

  // Initialize GA with the correct GA ID for the environment
  useEffect(() => {
    ReactGA.initialize(GA_ID);
  }, [GA_ID]);

  return (
    <Router>
      {/* Track page views on route changes */}
      <TrackPageView />

      <Navbar />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/funzone" element={<FunZone />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sdp" element={<ShippingDeliveryPolicy />} />
        <Route path="/rcp" element={<ReturnCancellationPolicy />} />
        <Route path="/pp" element={<PrivacyPolicy />} />
        <Route path="/tc" element={<TermsCondition />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/myorders" element={<OrderPage />} />
        <Route path="/order-details/:orderId" element={<OrderDetails />} />
        <Route path="/invoice/:orderId" element={<Invoice />} />
        <Route path="/dashboard" element={<GamesPage />} />
        <Route path="/vid" element={<VideoCardsPage />} />
        <Route path="/levels/:gameCode" element={<ProductPage />} />
        <Route path="/tasks/:levelCode" element={<TasksPage />} />
        <Route path="/stem-learning-kit/:levelCode" element={<ProductDetailsPage />} />
        <Route path="/task-details/:taskCode" element={<VideoTabs />} />

        {/* Protected Route */}
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <CheckoutPage1 />
            </ProtectedRoute>
          }
        />
      </Routes>
      <DownloadAppButton />
      <ChatButton />
      <Footer />
    </Router>
  );
}

export default App;
