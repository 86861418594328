// // Action Types
// export const FETCH_ADDRESSES_REQUEST = "FETCH_ADDRESSES_REQUEST";
// export const FETCH_ADDRESSES_SUCCESS = "FETCH_ADDRESSES_SUCCESS";
// export const FETCH_ADDRESSES_FAILURE = "FETCH_ADDRESSES_FAILURE";
// export const EDIT_ADDRESS = "EDIT_ADDRESS";

// // Fetch Addresses
// export const fetchAddresses = () => async (dispatch) => {
//   dispatch({ type: FETCH_ADDRESSES_REQUEST });
//   try {
//     const token = localStorage.getItem("token"); // Assume token is stored in localStorage
//     const response = await fetch("${BASE_URL}create/student", {
//       method: "GET",
//       headers: {
//         Authorization: token,
//         "Content-Type": "application/json",
//       },
//     });

//     if (!response.ok) {
//       throw new Error(`Error fetching addresses: ${response.statusText}`);
//     }

//     const data = await response.json();
//     dispatch({
//       type: FETCH_ADDRESSES_SUCCESS,
//       payload: data.add,
//     });
//   } catch (error) {
//     dispatch({
//       type: FETCH_ADDRESSES_FAILURE,
//       payload: error.message,
//     });
//   }
// };

// // Edit Address
// export const editAddress = (updatedAddress) => async (dispatch) => {
//   try {
//     const token = localStorage.getItem("token");
//     const response = await fetch("${BASE_URL}create/student", {
//       method: "PUT",
//       headers: {
//         Authorization: token,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(updatedAddress),
//     });

//     if (!response.ok) {
//       throw new Error(`Error updating address: ${response.statusText}`);
//     }

//     dispatch({ type: EDIT_ADDRESS, payload: updatedAddress });
//   } catch (error) {
//     console.error("Error updating address:", error.message);
//   }
// };



// Action Types
// Action Types




// Action Types


import { getEnvironmentDetails } from "../../environment";

const { BASE_URL } = getEnvironmentDetails(); // Destructure BASE_URL
export const FETCH_ADDRESSES_REQUEST = 'FETCH_ADDRESSES_REQUEST';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_FAILURE = 'FETCH_ADDRESSES_FAILURE';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';

// Action to fetch addresses
export const fetchAddresses = (token) => async (dispatch) => {
  dispatch({ type: FETCH_ADDRESSES_REQUEST });
  try {
    const response = await fetch(`${BASE_URL}student/address`, {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      
    });
    if (!response.ok) {
      throw new Error('Failed to fetch addresses');
    }
    const data = await response.json();
    dispatch({ type: FETCH_ADDRESSES_SUCCESS, payload: data.address });
  } catch (error) {
    dispatch({
      type: FETCH_ADDRESSES_FAILURE,
      payload: error.message || 'Something went wrong',
    });
  }
};

// Action to add new address
export const addNewAddress = (addressData, token) => async (dispatch) => {
  try {
    const response = await fetch(`${BASE_URL}student/address`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(addressData),
    });
    if (!response.ok) {
      throw new Error('Failed to add address');
    }
    const data = await response.json();
    dispatch({ type: ADD_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    console.error('Error adding address:', error);
  }
};

// Action to update existing address
export const updateAddress = (addressData, token) => async (dispatch) => {
  try {
    const response = await fetch(`${BASE_URL}student/address`, {
      method: 'PUT',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(addressData),
    });
    if (!response.ok) {
      throw new Error('Failed to update address');
    }
    const data = await response.json();
    dispatch({ type: UPDATE_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    console.error('Error updating address:', error);
  }
};

// Action to delete address
export const deleteAddress = (addressId, token) => async (dispatch) => {
  try {
    const response = await fetch(`${BASE_URL}student/address${addressId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to delete address');
    }
    dispatch({ type: DELETE_ADDRESS_SUCCESS, payload: addressId });
  } catch (error) {
    console.error('Error deleting address:', error);
  }
};
