import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddresses, addNewAddress, updateAddress } from "../../features/products/addressActions";
import { Box, Button, Card, CardContent, CircularProgress, Typography, Radio, TextField,Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import decryptURI from "../modifier/decryptURI";
import {createRazorpayOrder} from "./createord"
import ReactGA from "react-ga4";
import { getEnvironmentDetails } from "../../environment";
// import ilp from "../../../public/ilplogo.png"

const CheckoutPage = () => {
  const { BASE_URL } = getEnvironmentDetails(); // Get the backend URL
  const dispatch = useDispatch();
  const { loading, addresses, error } = useSelector((state) => state.address);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});
  const [couponCode, setCouponCode] = useState(""); // State for coupon input
  const [discountAmount, setDiscountAmount] = useState(0); // Discount amount
  const [couponError, setCouponError] = useState(""); // Error message for invalid coupon
  const [newAddress, setNewAddress] = useState(null); // Initially null to hide input fields
  const token = localStorage.getItem("token"); // Replace with actual token
  const location = useLocation();
  const { state } = location;
  const { 
    productName,
    productCode, 
    productPrice, 
    productDiscount, 
    productDescription,
    
    productTitle, 
    productMedia, 
    productSpecifications 
  } = state || {};
  const pp = Math.round(productPrice - (productPrice * productDiscount) / 100)
  const pcode =productCode
  console.log(productCode)


  let couponName = ""; 
  const handleApplyCoupon = async () => {
    if (!couponCode) {
      setCouponError("Please enter a coupon code.");
      return;
    }
  
    try {
      const response = await fetch(`${BASE_URL}validate-coupon/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon_name: couponCode,
          level_code: productCode,
        }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        const { discount, expire_date, coupon_name } = result.data;
        couponName = result.data.coupon_name;
        setDiscountAmount(discount);
        setCouponError(""); 
        alert(`Coupon applied successfully! ${coupon_name}`);
  
        // Track coupon applied event with ReactGA
        ReactGA.event({
          category: "Coupon",
          action: "Apply Coupon",
          label: couponCode, // Coupon code applied
          value: discount,   // Discount applied
        });
      } else {
        setDiscountAmount(0);
        setCouponError(result.message || "Invalid Coupon Code");
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
      setCouponError("Failed to validate coupon. Please try again later.");
    }
  };
  
  
  console.log("name",couponName)
  
  // const coupon_name = result.data.coupon_name;
  const handleRazorpayPayment = (orderData) => {
    const options = {
      key: "rzp_live_D8HMBKOPYq1E2u", // Razorpay key
      amount: orderData.amount * 100,
      currency: "INR",
      name: "iLearnPlace",
      description: "STEM Learning Kits Payment",
      order_id: orderData.razorpay_order_id,
      ilporder_id: orderData.orderId,
      handler: async (response) => {
        alert("Payment Successful!");
        console.log("Payment Response:", response);
  
        // Prepare payment data
        const paymentData = {
          amount: orderData.amount,
          razorpay_order_id: orderData.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          order_id: orderData.orderId,
        };
  
        // Track successful payment event with ReactGA
        ReactGA.event({
          category: "Payment",
          action: "Payment Success",
          label: `Razorpay Order ID: ${orderData.razorpay_order_id}`,
          value: orderData.amount, // Total amount paid
        });
  
        try {
          const token = localStorage.getItem("token");
  
          const res = await fetch(`${BASE_URL}paymentverification/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token,
            },
            body: JSON.stringify(paymentData),
          });
  
          const result = await res.json();
  
          if (res.ok) {
            console.log("Payment verification successful", result);
            window.location.href = "/";
          } else {
            alert("Payment verification failed. Please try again later.");
          }
        } catch (error) {
          alert("Error verifying payment. Please try again.");
          console.error("Error verifying payment:", error);
        }
      },
      notes: {
        address: selectedAddress,
      },
      theme: {
        color: "#3399cc",
      },
    };
  
    const razorpay = new window.Razorpay(options);
    razorpay.open();
  
    razorpay.on("payment.failed", (response) => {
      alert("Payment Failed. Please try again.");
      console.error("Payment Failure Response:", response);
  
      // Track payment failure event with ReactGA
      ReactGA.event({
        category: "Payment",
        action: "Payment Failed",
        label: `Razorpay Order ID: ${orderData.razorpay_order_id}`,
      });
    });
  };
  
  


  // const totalPayable =  pp - discountAmount;
  const totalPayable = Math.round(pp - (pp * (discountAmount / 100)));
  const handleProceedToPayment = async () => {
    console.log("address", addresses[selectedAddress]?.address_id);
  
    if (selectedAddress === null) {
      alert("Please select a delivery address before proceeding to payment.");
      return;
    }
  
    const productCode = { pcode }; // Replace with actual product code
    const token = localStorage.getItem("token"); // Authorization token
    const add = addresses[selectedAddress]?.address_id;
  
    // Google Analytics Event: Proceed to Payment using ReactGA
    ReactGA.event({
      category: "Checkout",
      action: "Proceed to Payment",
      label: "Proceed to Payment Button",
      value: totalPayable, // Ensure totalPayable is defined in the scope
    });
  
    // Dispatch the action to create the Razorpay order
    dispatch(createRazorpayOrder(productCode, token, totalPayable, add, couponCode)).then((response) => {
      if (response.razorpay_order_id) {
        // Order creation successful
        handleRazorpayPayment(response);
      } else {
        // Check for specific failure reasons
        if (response?.payload?.error) {
          console.error("Order creation error:", response.payload.error);
          alert(`Failed to create order: ${response.payload.error.message}`);
        } else if (response?.error) {
          console.error("API call failed:", response.error);
          alert("Failed to create order. Please check your internet connection or try again later.");
        } else {
          console.error("Unexpected response:", response);
          alert("Failed to create order due to an unknown error.");
        }
      }
    });
  };
  
  

  useEffect(() => {
    dispatch(fetchAddresses(token));
  }, [dispatch, token]);

  const handleEditClick = (address) => {
    setIsEditing(true);
    setEditData(address);
  };

  const handleSaveClick = () => {
    dispatch(updateAddress(editData, token));
    setIsEditing(false);
    setEditData({});
    window.location.reload();
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditData({});
  };

  const handleAddNewAddressClick = () => {
    setSelectedAddress(null); // Deselect any currently selected address
    setIsEditing(false); // Exit edit mode if editing
    setNewAddress({ // Show input fields for adding new address
      address: "",
      district: "",
      state: "",
      pincode: "",
    });
  };

  const handleCancelAddNewAddress = () => {
    setNewAddress(null); // Hide input fields when canceled
  };

  const handleAddAddressSubmit = () => {
    dispatch(addNewAddress(newAddress, token));
    setNewAddress(null); // Hide input fields after submission
    window.location.reload();
  };

  // If loading, show spinner
  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ padding: 4, display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 4 }}>
      {/* Left Section (Delivery Address, Order Summary, Payment Options) */}
      <Box sx={{ flex: { xs: 1, md: 3 }, display: "flex", flexDirection: "column", gap: 4 }}>
        
        {/* Delivery Address Section */}
        <Card>
          <CardContent>
            <Typography variant="h6">DELIVERY ADDRESS</Typography>
            {addresses.map((address, index) => (
              <Box key={address.id} sx={{ mt: 2 }}>
                <Radio
                  checked={selectedAddress === index}
                  onChange={() => setSelectedAddress(index)}
                />
                <Typography variant="body1" sx={{ display: "inline", ml: 1 }}>
                  {isEditing && selectedAddress === index ? (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editData.address || ""}
                        onChange={(e) => setEditData({ ...editData, address: e.target.value })}
                        sx={{ mb: 1 }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editData.district || ""}
                        onChange={(e) => setEditData({ ...editData, district: e.target.value })}
                        sx={{ mb: 1 }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editData.state || ""}
                        onChange={(e) => setEditData({ ...editData, state: e.target.value })}
                        sx={{ mb: 1 }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editData.pincode || ""}
                        onChange={(e) => setEditData({ ...editData, pincode: e.target.value })}
                      />
                    </>
                  ) : (
                    <span>{`${address.address}, ${address.district}, ${address.state}, ${address.pincode}`}</span>
                  )}
                </Typography>
                {selectedAddress === index && !isEditing && (
                  <Button variant="outlined" sx={{ ml: 2 }} onClick={() => handleEditClick(address)}>
                    Edit
                  </Button>
                )}
                {selectedAddress === index && isEditing && (
                  <>
                    <Button variant="contained" sx={{ ml: 2 }} onClick={handleSaveClick}>
                      Save
                    </Button>
                    <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
            ))}

            {!newAddress && !isEditing && (
              <Button variant="contained" sx={{ mt: 2 }} onClick={handleAddNewAddressClick}>
                Add New Address
              </Button>
            )}

            {newAddress && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">ADD NEW ADDRESS</Typography>
                <TextField
                  fullWidth
                  label="Address"
                  variant="outlined"
                  value={newAddress.address}
                  onChange={(e) => setNewAddress({ ...newAddress, address: e.target.value })}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="District"
                  variant="outlined"
                  value={newAddress.district}
                  onChange={(e) => setNewAddress({ ...newAddress, district: e.target.value })}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="State"
                  variant="outlined"
                  value={newAddress.state}
                  onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Pincode"
                  variant="outlined"
                  value={newAddress.pincode}
                  onChange={(e) => setNewAddress({ ...newAddress, pincode: e.target.value })}
                />
                <Button variant="contained" sx={{ mt: 2 }} onClick={handleAddAddressSubmit}>
                  Submit
                </Button>
                <Button variant="outlined" sx={{ ml: 2, mt: 2 }} onClick={handleCancelAddNewAddress}>
                  Cancel
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>

        {/* Product Details Section */}
        <Card sx={{ display: "flex", alignItems: "center", padding: 2, border: "1px solid #ddd" }}>
          <Grid item>
            <img
              src={decryptURI(productMedia[0].media_url)}
              alt="Product"
              style={{ width: "100px", height: "auto", objectFit: "cover", marginRight: "16px" }}
            />
          </Grid>
          <Grid container spacing={1} sx={{ flex: 1 }}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                STEM LEARNING KIT - {productName} ({productCode}) 
              </Typography>
            </Grid>
            <Grid 
                item 
                xs={12} 
                sx={{ 
                  display: "flex", 
                  alignItems: "center", 
                  justifyContent: "flex-start", // Align items to the start
                  gap: 1 // Minimal spacing between items
                }}
              >
                <Typography variant="body1" sx={{ textDecoration: "line-through", color: "gray", mr: 1 }}>
                  ₹{productPrice}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold", color: "green", mr: 1 }}>
                ₹{pp}
              </Typography>

                <Typography variant="body2" sx={{ color: "green" }}>
                  {productDiscount}% Off
                </Typography>
              </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <Typography variant="body2" sx={{ color: "gray" }}>
                Delivery within 7 days | FREE
              </Typography>
            </Grid>
          </Grid>
        </Card>

        {/* Coupon Code Section */}
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h6">COUPON CODE</Typography>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Enter Coupon Code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <Button variant="contained" sx={{ mt: 2 }} onClick={handleApplyCoupon}>
                Apply Coupon
              </Button>
              {couponError && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {couponError}
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>

        {/* Payment Options Section */}
        <Card>
          <CardContent>
            <Typography variant="h6">PAYMENT</Typography>
            <Typography sx={{ fontSize: 14, color: "green", fontWeight: "bold", marginTop: 2 }}>
              Total Payable: ₹{totalPayable}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleProceedToPayment}
              sx={{ mt: 2 }}
            >
              Proceed to Payment
            </Button>
          </CardContent>
        </Card>
      </Box>

      {/* Right Section */}
      <Box sx={{ flex: { xs: 1, md: 1 }, order: { xs: 3, md: 2 } }}>
        <Card>
          <CardContent>
            <Typography variant="h6">PRICE DETAILS</Typography>
            <Typography variant="body1">
              Price (1 item): <span style={{ float: "right" }}>₹{productPrice}</span>
            </Typography>
            <Typography variant="body1">
               Current Price (1 item): <span style={{ float: "right" }}>₹{pp}</span>
            </Typography>
            <Typography variant="body1">
              Delivery Charges: <span style={{ float: "right", color: "green" }}> FREE</span>
            </Typography>

            {discountAmount > 0 && (
              <Typography variant="body1" sx={{ color: "green" }}>
                Coupon Discount: {discountAmount}% <span style={{ float: "right" }}>-₹{pp*discountAmount/100}</span>
              </Typography>
            )}
            <hr />
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Total Payable: <span style={{ float: "right" }}>₹{totalPayable}</span>
            </Typography>
            <Typography variant="body2" style={{ color: "green", marginTop: "10px" }}>
              Your Total Savings on this order: ₹{ productPrice - totalPayable}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default CheckoutPage;